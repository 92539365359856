<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="4">
              <b-form-group label="Название">
                <b-form-input
                  v-model="filter.name"
                  type="text"
                  autocomplete="false"
                  @input="updateFilter('name')"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Номер">
                <b-form-input
                  v-model="filter.number"
                  type="text"
                  autocomplete="false"
                  @input="updateFilter('number')"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                class="form-group-el-select"
                label="Тип"
              >
                <el-select
                  v-model="filter.containerTypeId"
                  class="form-control"
                  name="container-type-id"
                  placeholder=""
                  clearable
                  :loading="loading"
                  @input="updateFilter('containerTypeId')"
                >
                  <el-option
                    v-for="item in containerTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <b-card no-body>
      <b-card-header>
        Контейнеры
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{lastRefreshTime}} </small>
          <b-button
            :disabled="loading"
            @click="$refs.table.refresh()"
            variant="primary"
            size="sm"
            class="ml-1"
          >
            <i :class="{'fa-spin' : loading}" class="fa fa-refresh" />&nbsp;Обновить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options(contractorId)"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="actions"
            slot-scope="props"
          >
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              :href="'/contractors/id' + $store.state.user.ownerId + '/containers/id' + props.row.id"
              class="fa fa-eye fa-lg text-secondary table-action-button"
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import {ServerTable, Event} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import {containersTypesList, contractorsContainersList} from '../../services/api';
import {queryToUrl, URLToQuery} from '../../services/http';
import moment from 'moment';

Vue.use(ServerTable);

export default {
  name: 'ContractorContainers',
  components: {
    ServerTable,
    Event,
  },
  mixins: [notifications],
  props: ['contractorId'],
  data: function() {
    return {
      lastRefreshTime: null,
      containerTypes: [],
      filter: {
        name: '',
        number: '',
        containerTypeId: null,
        tab: 3,
      },
      loading: false,
      columns: ['id', 'name', 'number', 'containerType.name', 'actions'],
      data: [],
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  created() {
    this.initRouteParams();
  },
  mounted() {
    this.containersTypesList();

    const queries = this.$router.currentRoute.query;
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
       * Записываем параметр при
       * изменении страницы таблицы
       * и записываем в URL
       * */
    Event.$on('vue-tables.pagination', (data) => {
      this.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  methods: {
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        this.updateRouteParams();
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    async containersTypesList() {
      this.loading = true;
      const response = await containersTypesList();
      if (response && response.status === 200) {
        this.containerTypes = response.data.items.map((type) => ({
          value: type.id,
          text: type.name,
        }));
      }
      this.loading = false;
    },
    options(contractorId) {
      return {
        customFilters: ['name', 'number', 'containerTypeId'],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return contractorsContainersList(contractorId, Object.assign(data, {...this.filter}));
        },
        responseAdapter({data}) {
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'id': 'ID',
          'name': 'Название',
          'number': 'Номер',
          'containerType.name': 'Тип',
          'actions': '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      };
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.containerTypeId) {
        res.containerTypeId = this.filter.containerTypeId;
      }
      if (this.filter.name) {
        res.name = this.filter.name;
      }
      if (this.filter.number) {
        res.number = this.filter.number;
      }
      if (this.filter.tab) {
        res.tab = 3;
      }
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      if (this.filter.limit && this.filter.limit !== 10) {
        res.limit = this.filter.limit;
      } else {
        delete res.limit;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
       * Запись параметров из URL в this.filter
       * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        if (params.hasOwnProperty('containerTypeId')) {
          params.containerTypeId = Number(params.containerTypeId);
        }

        Object.assign(this.filter, params);
      }
    },
  },
};
</script>

<style lang="scss">
</style>

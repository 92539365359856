<template>
  <div class="animated fadeIn">
    <h4
      v-if="contractor"
      class="mb-3"
    >
      {{ contractor }}
    </h4>
    <b-tabs
      v-model="tabIndex"
      justified
      tabs
    >
      <b-tab title="Реквизиты">
        <contractor-requisites
          v-if="tabIndex === 0"
          :contractor-id="contractorId"
        />
      </b-tab>
      <b-tab title="Водители">
        <contractor-drivers
          v-if="tabIndex === 1"
          :contractor-id="contractorId"
        />
      </b-tab>
      <b-tab title="Транспортные средства">
        <contractor-vehicles
          v-if="tabIndex === 2"
          :contractor-id="contractorId"
        />
      </b-tab>
      <b-tab title="Контейнеры">
        <contractor-containers
          v-if="tabIndex === 3"
          :contractor-id="contractorId"
        />
      </b-tab>
      <b-tab title="Доверенности">
        <contractor-attorneys
          v-if="tabIndex === 4"
          :contractor-id="contractorId"
        />
      </b-tab>
      <b-tab title="Партнеры">
        <contractor-partners
          v-if="tabIndex === 5"
          :contractor-id="contractorId"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import notifications from '../../components/mixins/notifications';
import {customersContractorRead} from '@/services/api';

import ContractorRequisites from './ContractorRequisites.vue';
import ContractorDrivers from './ContractorDrivers.vue';
import ContractorVehicles from './ContractorVehicles.vue';
import ContractorContainers from './ContractorContainers.vue';
import ContractorAttorneys from './ContractorAttorneys.vue';
import ContractorPartners from './ContractorPartners.vue';
import {queryToUrl} from '@/services/http';

export default {
  name: 'ContractorInfo',
  components: {ContractorRequisites, ContractorDrivers, ContractorVehicles, ContractorContainers, ContractorAttorneys, ContractorPartners},
  mixins: [notifications],
  props: ['contractorId'],
  data: function() {
    return {
      contractor: '',
      tabIndex: 0,
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        (route) => route.name || route.meta.label,
      );
    },
  },
  watch: {
    tabIndex() {
      const tab = queryToUrl('', {tab: this.tabIndex});
      history.pushState(null, null, tab);
    },
  },
  mounted() {
    const queries = this.$router.currentRoute.query;
    if (queries.tab) {
      this.tabIndex = Number(queries.tab);
    }
    this.customersContractorRead();
  },
  methods: {
    async customersContractorRead() {
      this.loading = true;
      const response = await customersContractorRead(this.$store.state.user.ownerId, this.contractorId);
      if (response && response.status === 200) {
        this.contractor = response.data.contractor.name;
      }
      this.loading = false;
    },
  },
};
</script>

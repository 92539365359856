<template>
  <div class="animated fadeIn">
    <div class="terms-and-participants__contractors">
      <h6 class="terms-and-participants__title">
        Перевозчики партнеры
      </h6>
      <b-form-group
        class="form-group-el-select"
      >
        <el-select
          v-model="form.partners.value"
          class="form-control"
          name="route-way"
          placeholder="Добавить перевозчика"
          :filterable="true"
          :remote="true"
          reserve-keyword
          :remote-method="contractorRemote"
          :loading="loading"
          @focus="contractorRemote('')"
          @change="onContractorChoose(form.partners.value)"
        >
          <el-option
            v-for="item in form.partners.options"
            :key="item.value"
            :label="item.text"
            :value="item.value"
            :disabled="item.disabled"
          />
        </el-select>
      </b-form-group>
      <v-client-table
        id="dataTable"
        :data="partners.items"
        :columns="partners.columns"
        :options="partners.options"
        :theme="partners.theme"
      >
        <span
          slot="actions"
          slot-scope="props"
        >
          <a
            v-if="$store.getters.isAdmin"
            v-b-tooltip.hover.bottom="'Удалить'"
            class="fa fa-close fa-lg text-danger table-action-button"
            @click="deleteChosenContractor(props.row.id)"
          />
        </span>
      </v-client-table>
    </div>
  </div>
</template>

<script>
import notifications from '../../components/mixins/notifications';
import {
  customersContractorRead,
  contractorsPartnerList,
  customersContractorsList,
  customersContractorsPartnersCreate,
  customersContractorsPartnersDelete,
} from '@/services/api';
import moment from 'moment';

export default {
  name: 'ContractorRequisites',
  mixins: [notifications],
  props: ['contractorId'],
  data() {
    return {
      data: {
        customersContractorId: null,
      },
      form: {
        partners: {
          value: null,
        },
      },
      partners: {
        columns: ['id', 'contractor.name', 'contractor.email', 'contractor.trucksCount', 'actions'],
        options: {
          headings: {
            'id': 'ID',
            'contractor.name': 'Название',
            'contractor.email': 'Email',
            'contractor.trucksCount': 'Кол-во автомобилей',
            'actions': '',
          },
          sortable: ['id', 'contractor.name', 'contractor.email', 'contractor.trucksCount'],
          filterable: [],
          sortIcon: {base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort'},
          pagination: {
            chunk: 10,
            edge: true,
            nav: 'fixed',
          },
          perPage: 5,
          perPageValues: [5, 10, 25],
          texts: {
            count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
            first: 'Первая',
            last: 'Последняя',
            filter: 'Фильтр:',
            filterPlaceholder: 'Поисковый запрос',
            limit: 'Записи:',
            page: 'Страница:',
            noResults: 'Нет совпадающих записей',
            filterBy: 'Фильтр по {column}',
            loading: 'Загрузка...',
            defaultOption: 'Выбор {column}',
            columns: 'Столбцы',
          },
        },
        items: [],
        theme: 'bootstrap4',
      },
      loading: false,
    };
  },
  mounted() {
    this.customersContractorRead();
    this.contractorsPartnerList('');
    this.contractorRemote('');
  },
  methods: {
    async customersContractorRead() {
      this.loading = true;
      const response = await customersContractorRead(this.$store.state.user.ownerId, this.contractorId);
      if (response && response.status === 200) {
        this.data.customersContractorId = response.data.id;
      }
      this.loading = false;
    },
    async contractorsPartnerList(query) {
      this.loading = true;
      const response = await contractorsPartnerList(this.contractorId, {
        name: query,
        limit: 9999,
      });
      if (response && response.status === 200) {
        this.partners.items = response.data.items;
      }
      this.loading = false;
    },
    async contractorRemote(query) {
      this.loading = true;
      const response = await customersContractorsList(this.$store.state.user.ownerId, {
        name: query,
        limit: 100,
      });
      if (response && response.status === 200) {
        this.form.partners.options = response.data.items.map((item) => {
          return {value: item.id, text: item.contractor.name};
        });
      }
      this.updateContractorOptions();
      this.loading = false;
    },
    updateContractorOptions() {
      this.form.partners.options = this.form.partners.options.map((item) => {
        return {...item, ...{disabled: !!this.inContractorsList(item.value)}};
      });
    },
    inContractorsList(id) {
      return this.partners.items.find((contractor) => {
        return contractor.id === id;
      });
    },
    async onContractorChoose(partnerId) {
      this.loading = true;
      const response = await customersContractorsPartnersCreate(this.$store.state.user.ownerId, {
        contractor_id: this.data.customersContractorId,
        partner_id: partnerId,
      });
      if (response && response.status === 201) {
        this.partners.items = response.data;
      }
      this.form.partners.value = null;
      this.updateContractorOptions();
      this.loading = false;
    },
    async deleteChosenContractor(partnerId) {
      this.loading = true;
      const response = await customersContractorsPartnersDelete(this.$store.state.user.ownerId, {
        contractor_id: String(this.data.customersContractorId),
        partner_id: String(partnerId),
      });
      if (response && response.status === 200) {
        this.partners.items = response.data;
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

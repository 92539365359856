<template>
  <div class="animated fadeIn">
    <b-form>
      <b-form-group
        v-if="form.legalAddress"
        id="legalAddressInputGroup1"
        label-cols-md="3"
        label="Юридический адрес"
        label-for="legal-address"
      >
        {{ form.legalAddress }}
      </b-form-group>
      <b-form-group
        v-if="form.factualAddress"
        id="factualAddressInputGroup2"
        label-cols-md="3"
        label="Фактический адрес"
        label-for="actual-address"
      >
        {{ form.factualAddress }}
      </b-form-group>
      <b-form-group
        v-if="form.authorizedPerson"
        id="authorizedPersonInputGroup3"
        label-cols-md="3"
        label="Уполномоченное для подписания договора лицо"
        label-for="authorized-person"
      >
        {{ form.authorizedPerson }}
      </b-form-group>
      <b-form-group
        v-if="form.INN"
        id="INNInputGroup4"
        label-cols-md="3"
        label="ИНН"
        label-for="tin"
      >
        {{ form.INN }}
      </b-form-group>
      <b-form-group
        v-if="form.INNFile"
        id="INNFile"
        label-cols-md="3"
        label="ИНН Файл"
        label-for="tin"
      >
        <a
          :href="'/files/'+form.INNFile.file.guid"
          target="_blank"
          @click.prevent="downloadFile(form.INNFile.file)"
        >{{ form.INNFile.file.originalName }}</a>
      </b-form-group>
      <b-form-group
        v-if="form.KPP"
        id="KPPInputGroup5"
        label-cols-md="3"
        label="КПП"
        label-for="cio"
      >
        {{ form.KPP }}
      </b-form-group>
      <b-form-group
        v-if="form.checkingAccount"
        id="checkingAccountInputGroup6"
        label-cols-md="3"
        label="Расчетный счет"
        label-for="settlement-account"
      >
        {{ form.checkingAccount }}
      </b-form-group>
      <b-form-group
        v-if="form.OKVED"
        id="OKVEDInputGroup7"
        label-cols-md="3"
        label="ОКВЭД"
        label-for="okved"
      >
        {{ form.OKVED }}
      </b-form-group>
      <b-form-group
        v-if="form.OGRN"
        id="OGRNInputGroup8"
        label-cols-md="3"
        label="ОГРН"
        label-for="bin"
      >
        {{ form.OGRN }}
      </b-form-group>
      <b-form-group
        v-if="form.OGRNDate"
        id="OGRNDateInputGroup9"
        label-cols-md="3"
        label="Дата регистрации ОГРН"
        label-for="date-of-registration-of-bin"
      >
        {{ form.OGRNDate }}
      </b-form-group>
      <b-form-group
        v-if="form.OGRNFile"
        id="OGRNFile"
        label-cols-md="3"
        label="ОГРН Файл"
        label-for="tin"
      >
        <a
          :href="'/files/'+form.OGRNFile.file.guid"
          target="_blank"
          @click.prevent="downloadFile(form.OGRNFile.file)"
        >{{ form.OGRNFile.file.originalName }}</a>
      </b-form-group>
      <b-form-group
        v-if="form.bankName"
        id="bankNameInputGroup10"
        label-cols-md="3"
        label="Наименование банка"
        label-for="name-of-the-bank"
      >
        {{ form.bankName }}
      </b-form-group>
      <b-form-group
        v-if="form.correspondentAccount"
        id="correspondentAccountInputGroup11"
        label-cols-md="3"
        label="Корреспондентский счет"
        label-for="correspondent-account"
      >
        {{ form.correspondentAccount }}
      </b-form-group>
      <b-form-group
        v-if="form.BIK"
        id="BIKInputGroup12"
        label-cols-md="3"
        label="БИК"
        label-for="bik"
      >
        {{ form.BIK }}
      </b-form-group>
      <b-form-group
        v-if="form.authorizedPersonFile"
        id="authorizedPersonFile"
        label-cols-md="3"
        label="Файл подтверждающий уполномоченное для подписания договора лицо"
        label-for="tin"
      >
        <a
          :href="'/files/'+form.authorizedPersonFile.file.guid"
          target="_blank"
          @click.prevent="downloadFile(form.authorizedPersonFile.file)"
        >{{ form.authorizedPersonFile.file.originalName }}</a>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import notifications from '../../components/mixins/notifications';
import {customersContractorRead, downloadFile} from '../../services/api';
import moment from 'moment';
import {queryToUrl} from '../../services/http';
const formShape = {
  legalAddress: '',
  factualAddress: '',
  authorizedPerson: '',
  INN: '',
  INNFile: null,
  KPP: '',
  checkingAccount: '',
  OKVED: '',
  OGRN: '',
  OGRNDate: '',
  OGRNFile: null,
  bankName: '',
  correspondentAccount: '',
  BIK: '',
  authorizedPersonFile: null,
};

export default {
  name: 'ContractorRequisites',
  mixins: [notifications],
  props: ['contractorId'],
  data() {
    return {
      INNFile: null,
      OGRNFile: null,
      authorizedPersonFile: null,
      form: Object.assign({}, formShape),
      loading: false,
    };
  },
  mounted() {
    this.customersContractorRead();
  },
  methods: {
    downloadFile,
    async customersContractorRead() {
      this.loading = true;
      const response = await customersContractorRead(this.$store.state.user.ownerId, this.contractorId);
      if (response && response.status === 200) {
        for (const [key, value] of Object.entries(this.form)) {
          this.form[key] = response.data.contractor[key];
        }
        if (this.form.OGRNDate) {
          this.form.OGRNDate = moment(this.form.OGRNDate).format('DD.MM.YYYY');
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="4">
              <b-form-group label="ФИО">
                <b-form-input
                  v-model="filter.fio"
                  type="text"
                  autocomplete="false"
                  @input="updateFilter('fio')"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                class="form-group-el-select"
                label="Допуск"
              >
                <el-select
                  v-model="filter.status"
                  class="form-control"
                  name="status"
                  placeholder=""
                  :loading="loading"
                  @input="updateFilter('status')"
                >
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                class="form-group-el-select"
                label="Доверенность"
              >
                <el-select
                  v-model="filter.attorney"
                  class="form-control"
                  name="attorney"
                  placeholder=""
                  :loading="loading"
                  @input="updateFilter('attorney')"
                >
                  <el-option
                    v-for="item in attorneyOptions"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <b-card no-body>
      <b-card-header>
        Водители
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options(contractorId)"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="driver.fio"
            slot-scope="props"
          >{{ props.row.driver.surname+" "+props.row.driver.name }}<span v-if="props.row.driver.patronymic">{{ " "+props.row.driver.patronymic }}</span></span>
          <span
            slot="attorneys"
            slot-scope="props"
          >
            <span
              v-for="(attorney, index) in props.row.attorneys"
              :key="`attorney-${index}`"
            >
              <span><b>{{ attorney.number }}</b> ({{ formatDate(attorney.dateStart) }} - {{ formatDate(attorney.dateEnd) }}</span>)<br>
              <p v-if="attorney.file === undefined"></p>
              <p v-else>
                <a
                  :href="`/files/${ attorney.file.file.guid }`"
                  target="_blank"
                  @click.prevent="downloadFile(attorney.file.file)"
                >Скачать</a>
              </p>
            </span>
          </span>
          <span
            slot="driver.status"
            slot-scope="props"
          >
            <span
              v-if="props.row.driver.status === 0"
              class="text-secondary"
            >Новый</span>
            <span
              v-if="props.row.driver.status === 1"
              class="text-success"
            >Допущен</span>
            <span
              v-else-if="props.row.driver.status === 2"
              class="text-primary"
            >На верификации СБ</span>
            <span
              v-else-if="props.row.driver.status === 3"
              class="text-danger"
            >Не допущен</span>
            <span
              v-else-if="props.row.driver.status === 4"
              class="text-primary"
            >Проверка логистом</span>
            <span
              v-else-if="props.row.driver.status === 5"
              class="text-danger"
            >Отклонен</span>&ensp;
            <span
              v-if="props.row.driver.blockedSecurity"
              class="badge badge-danger"
            >Блокировка СБ</span>
          </span>
          <span
            slot="actions"
            slot-scope="props"
          >
            <a
              v-if="props.row.driver.status === 4"
              v-b-tooltip.hover.bottom="'Отправить на верификацию в СБ'"
              class="fa fa-mail-forward fa-lg text-success table-action-button"
              @click="customersContractorsDriverToVerification(props.row.id)"
            />
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              :href="'/contractors/id' + contractorId + '/drivers/id' + props.row.id"
              class="fa fa-eye fa-lg text-secondary table-action-button"
            />
            <a
              v-if="props.row.driver.status === 4"
              v-b-tooltip.hover.bottom="'Вернуть на редактирование'"
              class="fa fa-reply fa-lg text-primary table-action-button"
              @click="confirmReturnDriver(props.row.id, props.row.contractorDrivers)"
            />
            <a
              v-if="props.row.driver.status === 4"
              v-b-tooltip.hover.bottom="'Отклонить'"
              class="fa fa-close fa-lg text-danger table-action-button"
              @click="confirmRefusalDriver(props.row.id, props.row.contractorDrivers)"
            />
            <!--a
              v-if="props.row.driver.isCancel"
              v-b-tooltip.hover.bottom="'Снять пометку на удаление'"
              class="fa fa-unlock-alt fa-lg text-success table-action-button"
              @click="removeDeletionMark(props.row.driver.id)"
            /-->
          </span>
          <div
            slot="child_row"
            slot-scope="props"
          >
            <p v-if="props.row.driver.passportScreenFirst">
              <a
                :href="'/files/'+props.row.driver.passportScreenFirst.file.guid"
                target="_blank"
                @click.prevent="downloadFile(props.row.driver.passportScreenFirst.file)"
              >Паспорт (титульный разворот)</a>
            </p>
            <p v-if="props.row.driver.passportScreenSecond">
              <a
                :href="'/files/'+props.row.driver.passportScreenSecond.file.guid"
                target="_blank"
                @click.prevent="downloadFile(props.row.driver.passportScreenSecond.file)"
              >Паспорт (регистрация)</a>
            </p>
            <p v-if="props.row.driver.driverLicenseScreen">
              <a
                :href="'/files/'+props.row.driver.driverLicenseScreen.file.guid"
                target="_blank"
                @click.prevent="downloadFile(props.row.driver.driverLicenseScreen.file)"
              />
            </p>
          </div>
        </v-server-table>
      </b-card-body>
    </b-card>
    <b-modal
      v-model="confirmReturnDriverModal.show"
      :title="confirmReturnDriverModal.title"
      class="modal-warning"
      cancel-title="Назад"
      ok-title="Отправить"
      @ok="returnDriver(confirmReturnDriverId)"
    >
      <p>{{ confirmReturnDriverModal.desc }}</p>
      <b-form-textarea
        id="comment"
        v-model="confirmReturnDriverModal.comment"
        type="text"
        name="comment"
        rows="4"
        :placeholder="confirmReturnDriverModal.placeholder"
        autocomplete="comment"
        no-resize
      />
    </b-modal>
    <b-modal
      v-model="confirmRefusalDriverModal.show"
      :title="confirmRefusalDriverModal.title"
      class="modal-warning"
      cancel-title="Назад"
      ok-title="Отправить"
      @ok="refusalDriver(confirmRefusalDriverId)"
    >
      <p>{{ confirmRefusalDriverModal.desc }}</p>
      <b-form-textarea
        id="comment"
        v-model="confirmRefusalDriverModal.comment"
        type="text"
        name="comment"
        rows="4"
        :placeholder="confirmRefusalDriverModal.placeholder"
        autocomplete="comment"
        no-resize
      />
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import {ServerTable, Event} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import {
  contractorsDriverList,
  customersContractorsDriverToCancel,
  customersContractorsDriverToNew,
  customersContractorsDriverToVerification,
  contractorsDriverUncancel,
  downloadFile,
} from '../../services/api';
import moment from 'moment';
import {queryToUrl, URLToQuery} from '../../services/http';
Vue.use(ServerTable);

export default {
  name: 'ContractorDrivers',
  components: {
    ServerTable,
    Event,
  },
  mixins: [notifications],
  props: ['contractorId'],
  data: function() {
    return {
      lastRefreshTime: null,
      filter: {
        fio: '',
        attorney: null,
        status: null,
        tab: 1,
      },
      attorneyOptions: [
        {value: null, text: 'Все'},
        {value: 1, text: 'Действует'},
        {value: 0, text: 'Не действует'},
      ],
      statusOptions: [
        {value: null, text: 'Все'},
        {value: 1, text: 'Допущены к рейсам'},
        {value: 2, text: 'На верификации СБ'},
        {value: 0, text: 'Новые'},
        {value: 3, text: 'Не допущенные'},
        {value: 4, text: 'На проверке логистом'},
        {value: 5, text: 'Отклоненые логистом'},
      ],
      confirmReturnDriverId: null,
      confirmReturnDriverModal: {
        show: false,
        title: '',
        desc: '',
        label: '',
        placeholder: '',
        comment: '',
      },
      confirmRefusalDriverId: null,
      confirmRefusalDriverModal: {
        show: false,
        title: '',
        desc: '',
        label: '',
        placeholder: '',
        comment: '',
      },
      loading: false,
      columns: [
        'id',
        'driver.fio',
        'attorneys',
        'driver.status',
        'driver.validationStatus',
        'driver.decisionOnDriver',
        'actions',
      ],
      data: [],
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  created() {
    this.initRouteParams();
  },
  mounted() {
    const queries = this.$router.currentRoute.query;
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    const that = this;
    Event.$on('vue-tables.pagination', (data) => {
      that.filter.page = data;
      that.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  methods: {
    downloadFile,
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },
    options(contractorId) {
      return {
        customFilters: ['fio', 'attorney', 'status'],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return contractorsDriverList(contractorId, Object.assign(data, this.updateRouteParams()));
        },
        responseAdapter({data}) {
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'id': 'ID',
          'driver.fio': 'ФИО',
          'attorneys': 'Доверенности',
          'driver.status': 'Допуск',
          'actions': '',
          'driver.validationStatus': 'Состояние проверки',
          'driver.decisionOnDriver': 'Решение проверки',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      };
    },
    async customersContractorsDriverToVerification(contractorDriverId) {
      this.loading = true;
      const response = await customersContractorsDriverToVerification(contractorDriverId);
      if (response && response.status === 200) {
        this.showSuccess('Водитель отправлен на верификацию в СБ');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    async removeDeletionMark(driverId) {
      this.loading = true;
      const response = await contractorsDriverUncancel(this.contractorId, driverId);
      if (response && response.status === 200) {
        this.showSuccess('Водитель востановлен');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    async returnDriver(contractorDriverId) {
      this.loading = true;
      const response = await customersContractorsDriverToNew(contractorDriverId, this.confirmReturnDriverModal.comment);
      if (response && response.status === 200) {
        this.showSuccess('Водитель возвращен на редактирование');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    confirmReturnDriver(contractorDriverId, contractorDriver) {
      this.confirmReturnDriverId = contractorDriverId;
      this.confirmReturnDriverModal = {
        show: true,
        title: 'Возврат водителя на редактирование',
        desc: 'Для того, чтобы вернуть водителя на редактирование, необходимо указать причину возврата. Пожалуйста, опишите ее ниже.',
        placeholder: 'Укажите причину',
      };
      if (contractorDriver.length > 0 && contractorDriver[0].customerComment) {
        this.confirmReturnDriverModal.comment = contractorDriver[0].customerComment;
      }
    },
    async refusalDriver(contractorDriverId) {
      this.loading = true;
      const response = await customersContractorsDriverToCancel(contractorDriverId, this.confirmRefusalDriverModal.comment);
      if (response && response.status === 200) {
        this.showSuccess('Водитель отклонен');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    confirmRefusalDriver(contractorDriverId, contractorDriver) {
      this.confirmRefusalDriverId = contractorDriverId;
      this.confirmRefusalDriverModal = {
        show: true,
        title: 'Отклонение водителя',
        desc: 'Для того, чтобы отклонить водителя, необходимо указать причину отклонения. Пожалуйста, опишите ее ниже.',
        placeholder: 'Укажите причину',
      };
      if (contractorDriver.length > 0 && contractorDriver[0].customerComment) {
        this.confirmRefusalDriverModal.comment = contractorDriver[0].customerComment;
      }
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.fio) {
        res.fio = this.filter.fio;
      }
      if (this.filter.attorney !== null) {
        res.attorney = Number(this.filter.attorney);
      }
      if (this.filter.status !== null) {
        res.status = Number(this.filter.status);
      }
      if (this.filter.tab) {
        res.tab = 1;
      }
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: this.filter});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('attorney')) {
          params.attorney = Number(params.attorney);
        }
        if (params.hasOwnProperty('status')) {
          params.status = Number(params.status);
        }
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        Object.assign(this.filter, params);
      } else {
        const params = this.$store.getters.getFilterParams(this.$options.name);
        if (params) {
          Object.assign(this.filter, params);
        }
      }
    },
  },
};
</script>

<style lang="scss">
</style>

<template>
  <div
    v-if="this.$route.matched.length > 2"
    class="animated fadeIn"
  >
    <router-view />
  </div>
  <div
    v-else
    class="animated fadeIn"
  >
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group label="Номер">
                <b-form-input
                  v-model="filter.number"
                  type="text"
                  autocomplete="false"
                  @input="updateFilter('number')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Дата">
                <el-date-picker-input
                  v-model="filter.date"
                  type="date"
                  autocomplete="false"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                  @change="updateDateFilter('date')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата от">
                <el-date-picker-input
                  v-model="filter.date_from"
                  type="date"
                  autocomplete="false"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                  @change="updateDateFilter('date_from')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Дата до">
                <el-date-picker-input
                  v-model="filter.date_to"
                  type="date"
                  autocomplete="false"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                  @change="updateDateFilter('date_to')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Водитель"
              >
                <el-select-clearable
                  v-model="filter.drivers"
                  class="form-control"
                  name="drivers"
                  placeholder="Начните вводить фамилию, имя и/или отчество"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersContractorsDriversList"
                  :loading="loading"
                  @change="updateFilter('drivers')"
                  @clear="customersContractorsDriversList('')"
                >
                  <el-option
                    v-for="item in drivers"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Статус"
              >
                <el-select
                  v-model="filter.status"
                  class="form-control"
                  name="status"
                  placeholder=""
                  clearable
                  :loading="loading"
                  @input="updateFilter('status')"
                >
                  <el-option
                    v-for="item in statuses"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <b-card no-body>
      <b-card-header>
        Доверенности
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
          <b-button
            v-if="$store.getters.isAdminOrManager"
            variant="primary"
            size="sm"
            to="/attorneys/add"
            class="ml-2"
          >
            <i class="fa fa-plus" />&nbsp;Добавить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="date"
            slot-scope="props"
          >{{ formatDateView(props.row.date) }}</span>
          <span
            slot="dateStart"
            slot-scope="props"
          >{{ formatDateView(props.row.dateStart) }}</span>
          <span
            slot="dateEnd"
            slot-scope="props"
          >{{ formatDateView(props.row.dateEnd) }}</span>
          <span
            slot="drivers"
            slot-scope="props"
          >
            <span
              v-for="(contractorDriver, index) in props.row.contractorDrivers"
              :key="`contractorDriver-${index}`"
            >
              <a
                :href="`/contractors/id${contractorId}/drivers/id${contractorDriver.id}`"
                target="_blank"
              >{{ contractorDriver.driver.surname }} {{ contractorDriver.driver.name }} <span v-if="contractorDriver.driver.patronymic">{{ contractorDriver.driver.patronymic }}</span></a><br>
            </span>
          </span>
          <span
            slot="status"
            slot-scope="props"
          >
            <span
              v-if="props.row.status === 0"
              class="text-secondary"
            >На рассмотрении</span>
            <span
              v-if="props.row.status === 1"
              class="text-success"
            >Действует</span>
            <span
              v-if="props.row.status === 2"
              class="text-danger"
            >Аннулирована</span>
          </span>
          <span
            slot="actions"
            slot-scope="props"
          >
            <a
              v-if="props.row.status !== 2 && $store.getters.isAdminOrManager"
              v-b-tooltip.hover.bottom="'Редактировать'"
              :href="`/contractors/id${contractorId}/attorney/id${props.row.id}/edit`"
              class="fa fa-pencil fa-lg text-primary table-action-button"
            />
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              :href="`/contractors/id${contractorId}/attorney/id${props.row.id}`"
              class="fa fa-eye fa-lg text-secondary table-action-button"
            />
            <a
              v-if="props.row.status !== 1 && $store.getters.isAdminOrManager"
              v-b-tooltip.hover.bottom="'Верифицировать'"
              class="fa fa-unlock-alt fa-lg text-success table-action-button"
              @click="verifyAttorney(props.row.id)"
            />
            <a
              v-if="props.row.status !== 2 && $store.getters.isAdminOrManager"
              v-b-tooltip.hover.bottom="'Аннулировать'"
              class="fa fa-lock fa-lg text-danger table-action-button"
              @click="showCancelAttorneyModal(props.row)"
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
    <b-modal
      v-model="attorneyModal.show"
      class="modal-warning"
      ok-variant="success"
      ok-title="Принять"
      :title="'Довереность '+attorneyModal.attorney.number"
      @ok="cancelAttorney()"
    >
      <b-form-group
        class="form-group-el-select"
        label="Причина аннулирования"
      >
        <b-form-textarea v-model="attorneyModal.attorney.cancelComment" />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import ElDatePickerInput from '../../components/ElDatePickerInput.vue'
import ElSelectClearable from '../../components/ElSelectClearable.vue'
import Vue from 'vue';
import {ServerTable, Event} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import moment from 'moment';
import {customersContractorsDriversList, customersContractorsAttorneysList, contractorsAttorneyCancel, contractorsAttorneyUncancel} from '../../services/api';
import {arrayStringToNumber} from '../../components/mixins/helpers';
import {queryToUrl, URLToQuery} from '../../services/http';

Vue.use(ServerTable);

export default {
  name: 'ContractorAttorneys',
  components: {
    ServerTable,
    Event, ElSelectClearable, ElDatePickerInput
  },
  mixins: [notifications],
  props: ['contractorId'],
  data: function() {
    return {
      attorneyModal: {
        show: false,
        attorney: {
          name: '',
          cancelComment: '',
        },
      },
      lastRefreshTime: null,
      filter: {
        number: '',
        date: null,
        date_from: null,
        date_to: null,
        drivers: null,
        status: null,
        page: 1,
        limit: 10,
        tab: 4,
      },
      drivers: [],
      statuses: [
        {value: null, text: 'Все'},
        {value: '1', text: 'Действуют'},
        {value: '0', text: 'Аннулированы'},
        {value: '2', text: 'Аннулированы более 7и дней'},
      ],
      loading: false,
      columns: [
        'number',
        'date',
        'dateStart',
        'dateEnd',
        'drivers',
        'status',
        'actions',
      ],
      data: [],
      options: {
        customFilters: ['number', 'date', 'date_from', 'date_to', 'drivers', 'status'],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return customersContractorsAttorneysList(this.contractorId, Object.assign(data, this.updateRouteParams()));
        },
        responseAdapter({data}) {
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          number: 'Номер',
          date: 'Дата',
          dateStart: 'Период действия с',
          dateEnd: 'Период действия по',
          drivers: 'Водители',
          status: 'Статус',
          actions: '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  computed: {
    formStr() {
      const calculationFormData = {};
      if (this.filter.number) {
        calculationFormData.number = this.filter.number;
      }
      if (this.filter.date) {
        calculationFormData.date = this.formatDateMessage(this.filter.date);
      }
      if (this.filter.date_from) {
        calculationFormData.date_from = this.formatDateMessage(this.filter.date_from);
      }
      if (this.filter.date_to) {
        calculationFormData.date_to = this.formatDateMessage(this.filter.date_to);
      }
      if (this.filter.drivers.length) {
        calculationFormData.drivers = this.filter.drivers.map((id) => {
          return id;
        });
      }
      if (this.filter.status) {
        calculationFormData.status = this.filter.status;
      }
      if (this.filter.page && this.filter.page !== 1) {
        calculationFormData.page = this.filter.page;
      }
      if (this.filter.limit && this.filter.limit !== 10) {
        calculationFormData.limit = this.filter.limit;
      }
      return calculationFormData;
    },
  },
  created() {
    this.initRouteParams();
  },
  mounted() {
    this.customersContractorsDriversList('');
    const queries = this.$router.currentRoute.query;
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    const that = this;
    Event.$on('vue-tables.pagination', (data) => {
      that.filter.page = data;
      that.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  methods: {
    async customersContractorsDriversList(query) {
      this.loading = true;
      const response = await customersContractorsDriversList(this.contractorId, {
        fio: query,
        limit: 100,
      });
      if (response && response.status === 200) {
        this.drivers = response.data.items.map((item) => {
          let fio = item.driver.surname + ' ' + item.driver.name;
          if (item.driver.patronymic) {
            fio = fio + ' ' + item.driver.patronymic;
          }
          return {
            value: item.driver.id,
            text: fio,
          };
        });
      }
      this.loading = false;
    },
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    updateDateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] ? this.formatDateMessage(this.filter[field]) : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    formatDateView(date) {
      return moment(date).format('DD.MM.YYYY');
    },
    formatDateMessage(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    async verifyAttorney(attorneyId) {
      this.loading = true;
      const response = await contractorsAttorneyUncancel(this.contractorId, attorneyId);
      if (response && response.status === 200) {
        this.showSuccess('Доверенность верифицирована');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.number) {
        res.number = this.filter.number;
      }
      if (this.filter.date !== null) {
        res.date = this.formatDateMessage(this.filter.date);
      }
      if (this.filter.date_from !== null) {
        res.date_from = this.formatDateMessage(this.filter.date_from);
      }
      if (this.filter.date_to !== null) {
        res.date_to = this.formatDateMessage(this.filter.date_to);
      }
      if (this.filter.drivers !== null) {
        res.drivers = this.filter.drivers;
      }
      if (this.filter.status !== null) {
        res.status = this.filter.status;
      }
      if (this.filter.tab) {
        res.tab = 4;
      }
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: this.filter});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      if (this.filter.limit && this.filter.limit !== 10) {
        res.limit = this.filter.limit;
      } else {
        delete res.limit;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        if (params.hasOwnProperty('drivers')) {
          params.drivers = arrayStringToNumber(params.drivers);
        }
        Object.assign(this.filter, params);
      } else {
        const params = this.$store.getters.getFilterParams(this.$options.name);
        if (params) {
          Object.assign(this.filter, params);
        }
      }
    },
    async showCancelAttorneyModal(attorney) {
      this.attorneyModal.show = true;
      this.attorneyModal.attorney = attorney;
      this.attorneyModal.attorney.date = moment(attorney.date).format('YYYY-MM-DD');
      this.attorneyModal.attorney.dateStart = moment(attorney.dateStart).format('YYYY-MM-DD');
      this.attorneyModal.attorney.dateEnd = moment(attorney.dateEnd).format('YYYY-MM-DD');
    },
    async cancelAttorney() {
      this.loading = true;
      const response = await contractorsAttorneyCancel(
        this.attorneyModal.attorney.contractor.id,
        this.attorneyModal.attorney.id,
        this.attorneyModal.attorney,
      );
      if (response && response.status === 200) {
        this.showSuccess('Доверенность аннулирована');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
</style>
